import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { styled } from '@material-ui/core/styles'

import { rspTheme } from '../../styles/rspTheme'

export const OverviewSection = styled(Box)({
  boxShadow: '0px 15px 15px 0px rgba(0, 0, 0, 0.14)',
  padding: '88px 0',
  '& .mobileCtaContainer': {
    display: 'none',
  },
  '& .desktopCtaContainer': {
    display: 'block',
  },
  '@media (max-width: 980px)': {
    '& .headline': {
      fontSize: rspTheme.typography.h3.fontSize,
      letterSpacing: rspTheme.typography.h3.letterSpacing,
      lineHeight: rspTheme.typography.h3.lineHeight,
    },
    '& .mobileCtaContainer': {
      display: 'block',
    },
    '& .desktopCtaContainer': {
      display: 'none',
    },
  },
  '@media (max-width: 768px)': {
    padding: '48px 0',
    boxShadow: '0px -15px 15px 0px rgba(0, 0, 0, 0.14)',
  },
})

export const ContentBox = styled(Box)({
  width: 'calc(50% - 24px)',
  maxWidth: '520px',
  '@media (max-width: 980px)': {
    width: '100%',
    maxWidth: 'unset',
    marginBottom: '40px',
  },
})

export const IconGrid = styled(Grid)({
  width: '50%',
  justifyContent: 'space-between',
  alignSelf: 'center',
  flexWrap: 'nowrap',
  '@media (max-width: 980px)': {
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
    minWidth: 'unset',
  },
})

export const IconBox = styled(Box)({
  margin: '0 16px 36px',
  maxWidth: '170px',
  textAlign: 'center',
  '& .imageContainer': {
    margin: '0 auto 16px',
    minHeight: '126px',
  },
  '& p': {
    color: rspTheme.palette.primary.main,
    fontSize: rspTheme.typography.caption.fontSize,
    fontWeight: rspTheme.typography.caption.fontWeight,
    lineHeight: rspTheme.typography.caption.lineHeight,
    letterSpacing: rspTheme.typography.caption.letterSpacing,
    textTransform: 'uppercase',
  },
  '& div': {
    color: rspTheme.palette.primary.main,
    fontSize: rspTheme.typography.caption.fontSize,
    fontWeight: rspTheme.typography.caption.fontWeight,
    lineHeight: rspTheme.typography.caption.lineHeight,
    letterSpacing: rspTheme.typography.caption.letterSpacing,
    textTransform: 'capitalize',
  },
  '& p:first-of-type': {
    marginBottom: '16px',
  },
})
